import React, { Component } from 'react';
import logo from './bird.svg';

class Bird extends Component {

    constructor(props)
    {
        super(props);
        //if you have another AudioContext class use that one, as some browsers have a limit
        this.audioCtx = new (window.AudioContext || window.webkitAudioContext || window.audioContext)();
    }

    //duration of the tone in milliseconds. Default is 500
    //frequency of the tone in hertz. default is 440
    //volume of the tone. Default is 1, off is 0.
    //type of tone. Possible values are sine, square, sawtooth, triangle, and custom. Default is sine.
    //callback to use on end of tone
    beep(duration, frequency, volume, type, callback) 
    {

        var oscillator = this.audioCtx.createOscillator();
        var gainNode = this.audioCtx.createGain();

        oscillator.connect(gainNode);
        gainNode.connect(this.audioCtx.destination);

        if (volume){gainNode.gain.value = volume;};
        if (frequency){oscillator.frequency.value = frequency;}
        if (type){oscillator.type = type;}
        if (callback){oscillator.onended = callback;}

        oscillator.start();
        setTimeout(function(){oscillator.stop()}, (duration ? duration : 500));
    };


    handlePip()
    {
        console.log('pip');


        var button = this;
        button.disabled = true;
        this.beep(500, 2093, 1,'sine', function () {
            button.disabled = false;
        });
    }


    render() 
    {
      return (
          <div className="App-header">
            <img src={logo} className="App-logo" alt="kliknutím pípneš" onClick={() => { this.handlePip(); }} />
            <p>
              Zapípám pro tebe.
            </p>
          </div>
      );
    }

  }
  
  export default Bird;